import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Footer from '../navfooter/Footer';
import '../components/style/Shop.css';
import PaginationShop from './pagination/PaginationShop';
import MoneyFormat from '../utils/MoneyFormat';

const Shop = ({ setCartItemCount }) => {
    const [products, setProducts] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [cost, setCost] = useState(0);
    const [sk, setSk] = useState('');// State for search keyword
    const [arr, setArr] = useState([]); // State for current products to display
    const [currentPage, setCurrentPage] = useState(1); // State for pagination
    const [postsPerPage] = useState(20);// Items to display per page
    const navigate = useNavigate();
         // Pagination function
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    // Function to add items to cart
    const addTocart = (id) => {
        const product = products.find(item => item.id === id);
        const isLoggedIn = sessionStorage.getItem('login');
    
        if (!isLoggedIn) {
            // If not logged in, redirect to the SignIn page
            alert('Please log in to add items to your cart.');
            navigate('/signin'); 
            return;
        } 
        if (product) {
            setCartItems(prevCart => {    // Update the item quantity if it already exists in the cart
                const existingItem = prevCart.find(item => item.id === id);
                let newCart;
                if (existingItem) {
                    newCart = prevCart.map(item => item.id === id ? { ...item, quantity: item.quantity + 1 } : item);
                } else {
                    newCart = [...prevCart, { ...product, quantity: 1 }];     // Add new product to cart
                }
                sessionStorage.setItem('final', JSON.stringify(newCart));   // Update session storage and calculate total cost
                const totalCost = newCart.reduce((total, item) => total + (item.price * item.quantity), 0);
                setCost(totalCost);
                setCartItemCount(newCart.reduce((total, item) => total + item.quantity, 0));
                return newCart;
            });
        }
    };
         // Function to remove items from the cart
            const removeFromCart = (id) => {
                        setCartItems(prevCart => {
                        const existingItem = prevCart.find(item => item.id === id);
                            if (existingItem) {
                                const isLastItem = existingItem.quantity === 1;
                                const newCart = isLastItem
                                    ? prevCart.filter(item => item.id !== id)// Remove item if it's the last
                                    : prevCart.map(item => item.id === id ? { ...item, quantity: item.quantity - 1 } : item);
                                // Update session storage and recalculate total cost
                                sessionStorage.setItem('final', JSON.stringify(newCart));
                                setCartItemCount(newCart.reduce((total, item) => total + item.quantity, 0));
                                const totalCost = newCart.reduce((total, item) => total + (item.price * item.quantity), 0);
                                setCost(MoneyFormat(totalCost));
                                return newCart;
                 }
               return prevCart;// Return previous cart if item not found
        });
    };      
    //  fetch products and initialize cart items on component mount
    useEffect(() => {
        axios.get("https://itaypalengke.com/public/index.php/api/product_api").then((response) => {
            const fetchedProducts = response.data.data;
                setProducts(fetchedProducts);
                  // Set the initial products to display
                const first15Products = fetchedProducts.slice(0, 15);
                    setArr(first15Products);
                });
                        // Set the initial products to display
                const storedCart = JSON.parse(sessionStorage.getItem('final')) || [];
                        setCartItems(storedCart);
                        const totalCost = storedCart.reduce((total, item) => total + (item.price * item.quantity), 0);
                        setCost(totalCost);
                        setCartItemCount(storedCart.reduce((total, item) => total + item.quantity, 0));
                }, []); 
                       // Match function for filtering products based on search input
                const match = (item) => {
                 return (item.category.toLowerCase().includes(sk.toLowerCase()) || item.name.toLowerCase().includes(sk.toLowerCase()));
    };
                       // Search function to filter products based on keyword
                const search = () => {
                        const filteredProducts = products.filter(match);
                        setArr(filteredProducts);
                        setCurrentPage(1);// Reset to first page
    };
                const all = () => {
                    axios.get("https://itaypalengke.com/public/index.php/api/product_api").then((response) => {
                        setProducts(response.data.data);
                        setArr(response.data.data);
                        setSk('');// Clear search field
                    });
                 };
                         // Pagination logic
                const indexOfLastPost = currentPage * postsPerPage;
                const indexOfFirstPost = indexOfLastPost - postsPerPage;
                const currentPost = arr.slice(indexOfFirstPost, indexOfLastPost);
                const totalPosts = arr.length;

    return (
        <>
           <div className='row'>
                <h1 className='mb-4 p-4 text-success'>Shop here</h1>
                    <h5 className='text-secondary p-3'>i-Tay Palengke your choice for fresh and affordable products...</h5></div>
                       <br />
                          <button className='btn' onClick={search}>
                            <i className='bi bi-search text-secondary fs-2'></i>
                             </button>
                              <input className='srch' type="text" name="search" value={sk} onChange={(e) => setSk(e.target.value)} placeholder="Search...."/>
                              <hr />
                    <        div className='btn-show text-center'>
                        <button className='btn btn-success fs-2 ' onClick={all}>Show all Products</button>
                    </div>
               <hr />
            {currentPost.map((product) => {
     return (
            <div className='card-all d-inline-block d-lg-inline-flex' key={product.id}>
                 <div className="card" style={{ width: "18rem" }}>
                        <img src={`https://itaypalengke.com/public/storage/images/${product.image}`} className="card-img-top" alt={product.image} />
                            <div className="card-body">
                                <h5 className="card-title" id={`${product.id}name`}>{product.name}</h5>
                                <p className="card-text">{product.description}</p>
                                <p className="card-text">{product.category}</p>
                                <p className="card-text" id={`${product.id}price`}>₱ {product.price}</p>
                                <button className="btn btn-success me-5" onClick={() => addTocart(product.id)}>Add to cart</button>
                                <button className="btn btn-primary" onClick={() => addTocart(product.id)}>+</button>
                                <button className="btn btn-danger " onClick={() => removeFromCart(product.id)}>-</button>
                                <hr />
                                {cartItems.find(item => item.id === product.id) && <span>Order Quantity: {cartItems.find(item => item.id === product.id).quantity}</span>}
                            </div>
                        </div>
                    </div>
                   );
                })}
            <PaginationShop postsPerPage={postsPerPage} totalPosts={totalPosts} paginate={paginate} />
            <Footer />
        </>
    );
};

export default Shop;