import React, { useState } from 'react';
import { Routes, Route} from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

import './components/style/Body.css'
import NavBar from './navfooter/NavBar'
import Home from './pages/Home'
import Shop from './pages/Shop'
import Contact from './pages/Contact'
import About from './pages/About'
import SignUp from './pages/SignUp'
import SignIn from './pages/SignIn'
import Dashboard from './components/formgroup/Dashboard'
import ProductList from './pages/admin/ProductList'
import Create from './pages/admin/Create'
import Profile from  './components/formgroup/Profile'
import CategoryList from './pages/admin/CategoryList'
import CreateCat from './pages/admin/CreateCat'
import Cart from  './pages/Cart'
import Feedback from './components/formgroup/Feedback';
import PaginationShop from './pages/pagination/PaginationShop';
import Checkout from './checkoutorder/Checkout';
import DisplayUserOrder from './checkoutorder/DisplayUserOrder';
import UserInfo from '../src/components/formgroup/UserInfo';


const App = () => {
          
  const [cartItemCount, setCartItemCount] = useState(0);


          return (
            <div className='app'>
          
             
                <NavBar cartItemCount={cartItemCount} setCartItemCount={setCartItemCount} />
                <Routes>
                          <Route path="/home" element={<Home/>}/>
                          <Route path="/" element={<Home/>}/>
                          <Route path="/shop" element={<Shop setCartItemCount={setCartItemCount} />} />
                          <Route path="/pagination" element={<PaginationShop/>}/>
                          <Route path="/contact" element={<Contact/>}/>
                          <Route path="/about" element={<About />}/>
                          <Route path="/signup" element={<SignUp />}/>
                          <Route path="/signin" element={<SignIn/>}/>           
                           <Route path="/dashboard" element={<Dashboard/>}/>
                           <Route path="/feedback" element={<Feedback/>}/>
                           <Route path="/admin/profile" element={<Profile/>}/>
                           <Route path="/admin/products" element={<ProductList/>}/>
                           <Route path="/admin/products/create" element={<Create/>}/>
                           <Route path="categorylist" element={<CategoryList/>}/>
                           <Route path="/categorylist/createcat" element={<CreateCat/>}/>
                           <Route path="/checkout" element={<Checkout/>}/>
                           <Route path="/cart" element={<Cart Shop setCartItemCount={setCartItemCount}/>}/>
                           <Route path="/display" element={<DisplayUserOrder/>}/>
                           <Route path="/userinfo" element={<UserInfo/>}/>

                </Routes>
                          

            </div>
  )
}

export default App;




