import React from 'react';
import Delete from './DeleteUserOrder'; // Import Delete component for order deletion
import MoneyFormat from '../utils/MoneyFormat'; // Import MoneyFormat for currency formatting

const UserOrder = ({ arr, setArr }) => {
  // Check if there are any orders in the array
  if (arr.length === 0) {
    return <p className='text-center mt-5'>
      <strong>No data found.</strong>
      </p>;
  }

  return (
    <>
      <h1 className='text-center text-success p-4'>Users Order</h1>
      <div className='container border border-success d-flex justify-content-center align-items-center'>
        <table className='table table-bordered table-responsive text-center'>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Number</th>
              <th>Address</th>
              <th>Product Name</th>
              <th>Image</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Total Amount</th>
              <th>Payment Method</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {/* Loop through each order in the array */}
            {arr.map((order) => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{order.fname}</td>
                <td>{order.lname}</td>
                <td>{order.email}</td>
                <td>{order.number}</td>
                <td>{order.address}</td>
                <td>{order.name}</td>
                <td><img
                      src={`https://itaypalengke.com/public/storage/images/${order.image}`}
                      alt={order.image}
                      style={{ height: '100px', width: 'auto', objectFit: 'contain' }}
                    />
                </td>
                  <td>&#8369;{MoneyFormat(order.price)}</td>
                  <td>{order.quantity}</td>
                  <td>&#8369;{MoneyFormat(order.price * order.quantity)}</td>
                   <td>{order.paymethod}</td>
                <td>
                  <Delete
                    id        = {order.id}
                    fname     = {order.fname}
                    lname     = {order.lname}
                    email     = {order.email}
                    address   = {order.address}
                    number    = {order.number}         
                    paymethod = {order.paymethod}
                    arr       = {arr}
                    setArr    = {setArr}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UserOrder;