import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Link} from 'react-router-dom';
import './Header.css'


const Header = () => {

  const navigate = useNavigate();


    const shop = ()=>{

        navigate('/shop')
    }

  return (
        <div className='header'>
                <div className="header-contents">
                    <h2 className='text-center '>Make Meals Better!</h2>
                    <h5 className='text-success mt-4 '>Make your meals impossible <br/> through our palengke,<br/> We love to serve you better.</h5>
                    <button className='btn btn-warning' onClick={shop}>View Shop</button>
                </div>
            </div>
        
  )
}

export default Header