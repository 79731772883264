import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import CreateCat from './CreateCat';
import Footer from '../../navfooter/Footer';
import EditCat from './EditCat';


const CategoryList = () => {
         const [products, setProducts] = useState([]);
         const [isUpdating, setIsUpdating] = useState(0);
         const [arr, setArr] = useState();

useEffect(() =>{
         axios.get("https://itaypalengke.com/public/index.php/api/category_api")   
         .then(res =>{       
         if(res.data) {
            setProducts(res.data.data)
         } else {
            setProducts([]);
         }
         })
 },[isUpdating]);

 const del = (id) =>{
         let getData = new FormData();
         getData.append('delid',id);


         axios({

            method : 'POST',
            data : getData,
            url: 'https://itaypalengke.com/public/index.php/api/category_api/delete/' + id,

         }).then(response =>{
            setIsUpdating([]);
            alert("deleted!");
         });
     }
    
  return (
    <>
       <div className='container p-5 col-sm-12 col-md-12 border border-success'>
                           <h1 className='text-center text-success mb-4'>Category List</h1>
                           <div className='row mb-3'>
                           <div className='col'>
                           <Link className='btn btn-primary me-1' to='/categorylist/createcat'>Create Products</Link> 
                           </div>
                           <div className='col'>
                           </div>
                           </div>
                           <table className='table border-dark'>
                           <thead>
                           <tr>
                           <th>ID</th>
                           <th>Category</th>
                           <th>Image</th>
                           <th>Action</th>
                           </tr>
                           </thead>
                           <tbody> 
                       {
                           products.map((product, index) => {
                           return (
                           <tr key={index}>
                           <td>{product.id}</td>
                           <td>{product.catname}</td>                            
                           <td><img src={("https://itaypalengke.com/public/storage/images/"+ product.img)}
                                        width="100" /></td>     
                           <td style={{ width: "20px", whitSpace: "nowrap"}}>
                                           {/* Edit modal */}
                            <button type="button" className='btn btn-success btn-sm' data-bs-toggle="modal" data-bs-target=  {"#editModal" + product.id} >Edit</button>
                            <EditCat
                            id       = {product.id}
                            category = {product.catname}
                            image    = {product.img}
                            arr      = {arr}
                            setArr   = {setProducts} />
                            <button type="button" className='btn btn-danger btn-sm' data-bs-toggle="modal" data-bs-target=  {"#delModal" + product.id} >Delete</button>
                                                      {/* -----Delete modal---- */}
                           <div class="modal fade" id={"delModal" + product.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                           <div class="modal-dialog">
                           <div class="modal-content">
                           <div class="modal-header">
                           <h1 class="modal-title fs-5 text-danger" id="exampleModalLabel ">Are you sure you want to delete ?</h1>
                           <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                           </div>
                           <div class="modal-body border border-dark">
                           <label className='col-sm-4 col-form-label text-success'>Category :{product.catname}</label><br/><br/>   
                           <label className='col-sm-4 col-form-label text-success'>Image :<img src={("https://itaypalengke.com/public/storage/images/"+ product.image)} width="100"/></label>
                           </div>
                           <div class="modal-footer">
                           <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                           <button 
                            type            = "button"
                            class           = "btn btn-danger"
                            id              = {product.id}
                            onClick         = {() => del(product.id)}
                            data-bs-dismiss = "modal">
                            Confirm Delete
                            </button>
                            </div>
                            </div>
                            </div>
                            </div>          
                            </td>
                            </tr>
                            )
                            })
                            }
                           </tbody>
                           </table>
                           </div>
                          <Footer/>
    </>
  )
}

export default CategoryList;