import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import  Footer from '../navfooter/Footer';
import MoneyFormat from '../utils/MoneyFormat';
import AuthContext from '../pages/context/AuthContext';
import '../components/style/Cart.css';

const Cart = ({ setCartItemCount }) => {
        const { user } = useContext(AuthContext); 
        const navigate = useNavigate();
        const [cartItems, setCartItems] = useState([]);
        const [cost, setCost] = useState(0);

     // Function to empty the cart
  const empty = () => {
        sessionStorage.removeItem('final');
        setCartItems([]);
        setCost(0);
        setCartItemCount(0);
  };
     // Function to delete an item from the cart
  const deleteItem = (id) => {
          const filteredItems = cartItems.filter(item => item.id !== id);
          sessionStorage.setItem('final', JSON.stringify(filteredItems));
          setCartItems(filteredItems);
          setCost(filteredItems.reduce((total, item) => total + (item.price * item.quantity), 0));
          setCartItemCount(filteredItems.reduce((total, item) => total + item.quantity, 0));
  };

  // Effect to load cart items from session storage on component mount
  useEffect(() => {
          const sesh = JSON.parse(sessionStorage.getItem('final'));
          if (sesh) {
            setCartItems(sesh);
            const totalCost = sesh.reduce((total, item) => total + (item.price * item.quantity), 0);
            setCost(totalCost);
          }
  }, []);

  const handlePlaceOrder = () => {
           navigate('/checkout', { state: { cartItems, cost } });
  };

  return (
    <>
        <div className="d-flex justify-content-center align-items-center cart-container">
               <div className="cart-content w-75 border rounded p-3">
                     <div className="table-container">
                         <h1 className="text-center text-success">My Cart</h1>
                          <table className='table table-bordered table-responsive text-center'>
                               <thead>
                                     <tr>
                                        <th>Item Name</th>
                                        <th>Image</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Total</th>
                                        <th>Actions</th>
                                     </tr>
                              </thead>
                        <tbody>
                              {cartItems.map(item => (
                                <tr key={item.id}>
                                  <td>{item.name}</td>
                                       <td> 
                                          <img
                                            src={`https://itaypalengke.com/public/storage/images/${item.image}`}
                                            alt={item.name}
                                            style={{ height: '100px', width: 'auto', objectFit: 'contain' }} />
                                         </td>
                                           <td>₱ {MoneyFormat(item.price)}</td>
                                            <td>{item.quantity}</td>
                                           <td>₱ {MoneyFormat(item.price * item.quantity)}</td>
                                        <td>
                                    <Button className="btn btn-danger" onClick={() => deleteItem(item.id)}>Delete</Button>
                              </td>
                      </tr>
                ))}
              </tbody>
            </table>
                   </div>
                        <div className="cart-summary text-center">
                              <h1 className="text-center text-success">Cart Summary</h1>
                                 <h4 className='text-secondary'>Total of items: </h4>
                                   <h4 className='text-secondary'>{cartItems.reduce((total, item) => total + item.quantity, 0)}</h4>
                                     <h4 className='text-secondary'>Total Amount:</h4>
                                    <h4 className='text-secondary'>₱ {MoneyFormat(cost)}</h4>
                                    <div className="text-center p-2 m-4">
                                  <Button className="btn btn-danger m-2 " onClick={empty}>Empty Cart</Button>
                                 <Button className="btn btn-success m-2 " onClick={handlePlaceOrder}>Check Out</Button>
                             </div>
                           </div>
                         </div>
                      </div>
                <Footer />
    </>
  );
};

export default Cart