import React from 'react';
import axios from 'axios';

const DeleteUserOrder = ({ id, setArr }) => {

    const delBtn = (e) =>{
          let getData = new FormData();
          getData.append('delid', id);
 
        
         axios( { 
              method : 'POST',
              url : 'https://itaypalengke.com/public/index.php/api/cart_api/delete/' + id,
              data : getData, 
          }).then((response)=>{
            console.log(response.data.data);
              alert("deleted");
              axios.get('https://itaypalengke.com/public/index.php/api/cart_api').then((res)=>{
                  setArr (res.data.data)
             });
    
      
        });
    }
 return (
        <div>
            <button className="btn btn-danger" onClick={delBtn}>Delete</button>
        </div>
    );
};

export default DeleteUserOrder;