import React from 'react'
import {Link} from 'react-router-dom'
import Fb from '../image/fb.png'
import Twit from '../image/twit.png'
import Tik from '../image/tik.png'
import Mail from '../image/mail.png'
import logo from '../image/itays.png'
import Tel from  '../image/Teleg.png'
import '../components/style/Footer.css'


const Footer = () => {
  return (
    <>
        <div className='footer col-sm-6 col-md-8 col-lg-12' id='footer'>
               <div className="footer-content col-md-6 col-lg-5 col-12 ">  
                         <div  className = 'footer-content-left ol-sm-4 col-md-8 col-lg-12'>
                         <Link class     = "navbar-brand" to = "/home">
                         <img  src       = {logo} alt        = '' className = 'logo' /> </Link>
                         </div>
                         <div className = 'footer-content-center col-4 col-md-2'>
                         <h2>Company</h2>
                         <ul>
                         <li   className = "footer-item fs-5">
                         <Link className = "nav-a" to = "/">Home</Link>
                         </li>
                         <li   className = "footer-item fs-5">
                         <Link className = "nav-a" to = "/about">About</Link>
                         </li>
                         <li className = 'fs-5'>Privacy policy</li>
                         </ul>
                         </div>    
                         <div className='footer-content-right'>
                         <h2>CONTACT</h2>
                         <ul>
                         <li><i className='bi-telephone fs-5 me-3'></i> 09615806261</li> 
                         <li> <i className='bi-envelope fs-5 me-3'></i> vencitomarl@gmail.com</li> 
                         <li> <i className='bi-send fs-5 me-3'></i> Tacloban Leyte</li> 
                         </ul>
                         </div>
                         </div>
                         <hr />
                        <div  className = 'footer-social-icons'>
                        <Link className = 'social-icon'  to = "https://web.facebook.com/">
                        <img  src       = {Fb} alt          = '' className = 'fb'/> </Link>
                        <Link className = 'social-icon'  to = "https://x.com/i/flow/login">
                        <img  src       = {Twit} alt        = '' className = 'twit'/> </Link>
                        <Link className = 'social-icon'  to = "https://www.tiktok.com/">
                        <img  src       = {Tik} alt         = '' className = 'tik'/> </Link>
                        <Link className = 'social-icon'  to = "https://web.telegram.org">
                        <img  src       = {Tel} alt         = '' className = 'teleg'/> </Link>
                        <Link className = 'social-icon'  to = "https://accounts.google.com/">
                        <img  src       = {Mail} alt        = '' className = 'mail'/> </Link>
                        </div>
                    <br/>
                  <p className='footer-copyright'>Copyright @ 2024 venzorro - Alright reserved </p>
             </div>
    </>
  )
}

export default Footer