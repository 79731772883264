import React from 'react'
import Footer from '../navfooter/Footer';
import {useNavigate} from 'react-router-dom'

const About = () => {
    const navigate = useNavigate();

    const shop = ()=>{

        navigate('/shop')
    }
  return (
    <>
        <div className="hero">
                <h1 className="text-success text-center p-2">About Us</h1>   
                     <div className='container border border-success'>
                            <div className='row justify-content-between'>
                                <div className='col-sm-4 col-lg-5 p-4'> 
                                    <h5 className='p-2 text-secondary'>The most quality and fresh products with good prices, Shop now for fast delivery and excellent customer service... </h5>         
                                   <div>
                                   <div>
                                 <div>
                               <button className='btn custom-button bg-success text-white border-dark fs-5' onClick={shop}>Shop Now</button>
                            </div>
                          </div>
                       </div>
                    </div>
                   <div className='col-sm-4 col-lg-5 d-flex justify-content-end'>
                 <img className="img-fluid" src={require('../image/aa.png')} alt='...'
                 width="400px"/>
            </div>
        </div>
    </div>
</div>
<br/>
     <br/>
         <div className="container mt-2 p-2 border border-success">
               <div className="block">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-8 pb-2">
                                <form>
                                    <div className="row align-items-start">
                                        <div className="col-md-6 mb-4 text-start p-5">
                                            <h2 className="text-center mb-4 text-success">Our Mission</h2>
                                               <hr className='text-primary'></hr>
                                                <h5 className='text-dark'>To be more reliable and efficient to prepare meals in an instant without compromising your time and effort,
                                                    able to do it alone without the help of others especially in times of need, sudden occasions and guest at home</h5>
                                                     </div>
                            <                        div className="col-md-6 mb-8 text-start p-5">
                                                    <h2 className="text-center mb-4 text-success">Our Vision</h2>
                                                    <hr className='text-primary'></hr>
                                                <h5 className='text-dark'>The innovative lifestyle and captivates unique ideas that fascinate the new generation and makes more co-conceptualized 
                                              and observant in the useful purposes of the website for modern community living</h5>                   
                                         </div>
                                    </div>
                               </form>
                           </div>
                       </div>
                 </div>
             </div>
                        
    < Footer />
</>
);
}

export default About;