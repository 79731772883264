import React from 'react'
import Edit from './Fedit'
import Delete from './Fdelete'

const Fback = ({ arr, setArr }) => {

  return (
    <>
     <div className='container p-5 col-sm-12 col-md-6 col-lg-12 border border-success'>
      <h1 className='text-center p-2'>Users Feedback Form</h1>
      <hr/>
      {arr.map((item)=>{
           return(
           
          <div className="d-inline-block p-4 mb-8 border border-dark" style={{ width: "18rem", margin: "8px" }} key={item.id}>
              <div className="col-12 mb-4 ">

                <ul>
                  <li className="card-title">Full Name: <br/>{item.fname}</li>
                  <li className="card-title">Company : <br/>{item.company}</li>
                  <li className="card-title">Email:<br/>{item.email}</li>
                  <li className="card-title">Phone # :<br/>{item.phone}</li>
                  <li className="card-title">Subject : <br/>{item.subject}</li>
                  <li className="card-title"> Message:<br />
                  <textarea
                    className="form-control" id="message" rows="4"style={{ resize: 'none', width: '100%', marginTop: '5px' }} placeholder=""
                    value={item.message}
                    readOnly  
                  ></textarea>
                 </li>
                   
            </ul>  
         
        <div className='card-footer mb-4 text-center'>
                                <button 
                                        className="btn btn-success" 
                                        data-bs-toggle="modal"
                                        data-bs-target={"#editModal"+item.id}
                                 
                                    >  Edit</button>
                                <button 
                                        className="btn btn-danger"
                                        data-bs-toggle="modal"
                                        data-bs-target={"#delModal"+item.id}   
                                    >Delete</button>

        </div>
               
          <Edit
            id={item.id} 
            fname={item.fname}
            company={item.company}
            email={item.email}
            phone={item.phone}
            subject={item.subject}
            message={item.message}
            arr={arr} setArr={setArr}
            
             />
            <Delete
              id={item.id} 
              fname={item.fname}
              company={item.company}
              email={item.email}
              phone={item.phone}
              subject={item.subject}
              message={item.message}
              arr={arr} setArr={setArr}
            
             />


           </div>
        
           </div>
          
        )
       
    })
    
    }
   </div>
</>

  )
}

export default Fback


