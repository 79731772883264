import React from 'react';
import axios from 'axios'
import {useState} from 'react'

const EditCat = ({arr, setArr, id, catname,description, img}) => {
   
        const [editcatname, setECatname] = useState(catname);
        const [editdescription, setEDescription] = useState(description);
        const [editImg, setEimg] = useState(img);
        const [editImgF, setEimgF] = useState(img);
        
        const url = 'https://itaypalengke.com/public/index.php/api/category_api'; 
        const preview = (e) => {
            setEimg(e.target.files[0]);
            setEimgF(e.target.files[0].name);
            console.log(editImgF);
    };
const editSubmit = (e) => {
        e.preventDefault();
        const getData = new FormData();
        getData.append('eid', id); 
        getData.append('editcatname', editcatname);
        getData.append('editdescription', editdescription);
        getData.append('editimg', editImg);
        getData.append('editimgF', editImgF);
    
        axios.post(`https://itaypalengke.com/public/index.php/api/category_api/edit/${id}`, getData, {  
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            alert("Category updated successfully!");
            return axios.get(url);
        })
        .then((res) => {
            setArr(res.data.data); // Update the category list with fresh data
        })
        .catch((error) => {
            console.error("Error editing category:", error.response ? error.response.data : error.message);
        });
    
  }
 return (
        <div>
             <div className="modal fade" id={"editModal" + id} tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                 <div className="modal-dialog">
                       <div className="modal-content">
                          <div className="modal-header">
                               <h1 className="modal-title fs-4 text-success" id="editModalLabel">Edit Product</h1>
                                   <button type="button" className="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
                                      </div>
                    <                   div className="modal-body">
                                         <form className='form-group'>
                                            <label className='col-sm-4 col-form-label'>Category List</label>
                                              <div className='col-sm-8 '>
                                                <select className='form select' name='category' onChange={(e) => setECatname(e.target.value)} required>
                                                    <option value = ''>Select Category Name</option>
                                                    <option value = 'Other'>Other</option>
                                                    <option value = 'Vegetables'>Vegetables</option>
                                                    <option value = 'Fruits'>Fruits</option>
                                                    <option value = 'Meat'>Meat</option>
                                                    <option value = 'Seafood'>Seafood</option>
                                                    <option value = 'Dried fish'>Dried Fish</option>
                                                 </select>
                                        <span className='text-danger'></span>
                                      </div> 
                             <div className='row mb-3 m-4'>
                                      <label className='col-sm-4 col-form-label'>Image</label>
                                        <div className='col-sm-8'>
                                             <input className='form-control' type='file' name='image' onChange={preview} required/>
                                                   <span className='text-danger'></span>
                                                    </div>
                                                     </div>
                                                       </form>
                                                       </div>
                                                        <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                 <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={editSubmit} id={id}>Save changes</button>
                                         </div>
                                    </div>
                                </div>
                            </div>
                     </div>
        
    )
 }
export default EditCat;
