import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from '../navfooter/Footer';
import MoneyFormat from '../utils/MoneyFormat';


const Checkout = () => {
    const location = useLocation();
    const { cartItems } = location.state || { cartItems: [] };
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [address, setAddress] = useState('');
    const [paymethod, setPaymethod] = useState('');
    const [cost, setCost] = useState(0);
    const [items, setItems] = useState(cartItems);
    const navigate = useNavigate();

    //pre open mo controller nga
    useEffect(() => {
        const sesh = JSON.parse(sessionStorage.getItem('final'));
        if (sesh) {
            const totalCost = sesh.reduce((total, item) => total + (item.price * item.quantity), 0);
            setCost(totalCost);
        }
    }, []);

    const formSubmit = (e) => {
        e.preventDefault();

        const myCartItems = JSON.stringify(items);
        let getData = new FormData();
        getData.append('fname', fname);
        getData.append('lname', lname);
        getData.append('email', email);
        getData.append('address', address);
        getData.append('number', number);
        getData.append('paymethod', paymethod);
        getData.append('totalprice', cost);
        getData.append('cart', myCartItems);

        axios.post('https://itaypalengke.com/public/index.php/api/cart_api/send', getData)
            .then((response) => {
                console.log("Response:", response);
                alert("Order placed successfully!");
                setFname("");
                setLname("");
                setEmail("");
                setAddress("");
                setPaymethod("");
                setCost("");
                setItems("")
                navigate('/shop')
            });
    };

    return (
        <>
            <div className="container mt-5 p-4 loginContainer border border-dark">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <div className="p-4">
                            <h1 className="text-center text-success mb-4">Order Details :</h1>
                            <hr className='text-secondary' />
                            <form className="loginForm" onSubmit={formSubmit}>
                                <div className='row'>
                                    <div className="col-6 mb-4">
                                        <label>First Name:</label><br />
                                        <input
                                            type        = "text"
                                            id          = "fname"
                                            name        = 'fname'
                                            value       = {fname}
                                            onChange    = {(e) => setFname(e.target.value)}
                                            className   = "form-control"
                                            placeholder = 'First Name'
                                            required
                                        />
                                    </div>
                                    <div className="col-6 mb-4">
                                        <label>Last Name:</label><br />
                                        <input
                                            type        = "text"
                                            id          = "lname"
                                            name        = 'lname'
                                            value       = {lname}
                                            onChange    = {(e) => setLname(e.target.value)}
                                            className   = "form-control"
                                            placeholder = 'Last Name'
                                            required
                                        />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label>Email Address:</label><br />
                                        <input
                                            type        = "email"
                                            id          = "email"
                                            name        = 'email'
                                            value       = {email}
                                            onChange    = {(e) => setEmail(e.target.value)}
                                            className   = "form-control"
                                            placeholder = 'Email'
                                            required
                                        />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label>Phone No:</label><br />
                                        <input
                                            type        = "number"
                                            id          = "number"
                                            name        = 'number'
                                            value       = {number}
                                            onChange    = {(e) => setNumber(e.target.value)}
                                            className   = "form-control"
                                            placeholder = 'Number'
                                            required
                                        />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label>Complete Address (Brgy/Street):</label><br />
                                        <input
                                            type        = "text"
                                            id          = "address"
                                            name        = 'address'
                                            value       = {address}
                                            onChange    = {(e) => setAddress(e.target.value)}
                                            className   = "form-control"
                                            placeholder = 'Address'
                                            required
                                        />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <label>Payment Methods</label>
                                        <select className = 'form-select' name = 'payment' onChange = {(e) => setPaymethod(e.target.value)} required>
                                        <option value     = ''>Select Payment Method</option>
                                        <option value     = 'cash on delivery'>Cash on Delivery</option>
                                        <option value     = 'Gcash'>Gcash</option>
                                        <option value     = 'Paymaya'>Paymaya</option>
                                        <option value     = 'Credit Card'>Credit Card</option>
                                        </select>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button type="submit" className="btn btn-success btn-block mb-4">Place Order</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* Table Section */}
                    <div className="col-12 col-md-6 mt-5 mt-md-0">
                        <div className="p-4">
                            <h1 className='text-center text-success'>Order Items :</h1>
                            <h4 className="text-center text-secondary">Total No. of items: {items.reduce((total, item) => total + item.quantity, 0)}</h4>
                            <h4 className="text-center text-secondary">Total Amount: ₱ {MoneyFormat(cost)}</h4>
                            <table className='table table-bordered text-center'>
                                <thead>
                                    <tr>
                                        <th>Item Name</th>
                                        <th>Image</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.name}</td>
                                            <td>
                                                <img
                                                    src={`https://itaypalengke.com/public/storage/images/${item.image}`}
                                                    alt={item.name}
                                                    style={{ height: '100px', width: 'auto', objectFit: 'contain' }}
                                                />
                                            </td>
                                            <td>₱ {MoneyFormat(item.price)}</td>
                                            <td>{item.quantity}</td>
                                            <td>₱ {MoneyFormat(item.price * item.quantity)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Checkout;