import React, {useState} from 'react'
import axios from 'axios'
import {useNavigate, Link} from 'react-router-dom'

import Footer from '../navfooter/Footer'
import Fb from '../image/fb.png'
import Twit from '../image/twit.png'
import Mail from '../image/mail.png'
import Tel from  '../image/Teleg.png'
import '../components/style/SignIn.css'

const SignIn = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility
    const navigate = useNavigate();
    const bcrypt = require('bcryptjs');

    const check = (arr) =>{ //check user credentials
        return (arr.email == email && bcrypt.compareSync(password, arr.password) == true ) ;
    }
    const login = (e) => {
        e.preventDefault();
        let getData = new FormData();
        getData.append('email', email);
        getData.append('password', password);
          
         axios({
            method: 'GET',
            data: getData,
            url: 'https://itaypalengke.com/public/index.php/api/sample_api'
         }).then(
            axios.get('https://itaypalengke.com/public/index.php/api/sample_api').then((res)=>{
                if ((res.data.data).filter(check).length >= 1){ // Check if user credentials are found in the API response data
                    console.log(((res.data.data).filter(check))[0].fname)
                        sessionStorage.setItem('login', email);
                        sessionStorage.setItem('username', ((res.data.data).filter(check))[0].fname);// Get user data from response
                        sessionStorage.setItem('role', ((res.data.data).filter(check))[0].role );
                        navigate('/shop');
                 }else {
                    alert('Wrong email or password');
                     navigate('/signin');
                 }
                }))

    }

  return (
    <>
        <div className="container mt-4 p-4 ">
               <div className="row justify-content-center">
                           <div className="col-12 col-md-4">
                               <div className='container p-4 border border-success'>
                                 <h1 className="text-center text-success mb-4 ">Sign in</h1>
                                   <hr className='text-primary mb-4'></hr>
                                   <form >                        
                                   <div className="form-outline mb-4">
                                   <input type="text" id="emailOrMobile" name='email' value={email} onChange={(e)=> setEmail(e.currentTarget.value)} className="form-control" placeholder='Email or Mobile Number'/>
                                   </div>
                                   <div className="form-outline mb-4 position-relative">
                                    <input type={passwordVisible ? 'text' : 'password'} id="password" name='password' value={password} onChange={(e) => setPassword(e.currentTarget.value)} className="form-control" placeholder='Password'/>
                                    <span className="position-absolute" style={{ right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }} onClick={() => setPasswordVisible(!passwordVisible)}>
                                     {passwordVisible ? <i className='bi bi-eye-slash me-2 fs-5'></i> : <i className='bi bi-eye me-2 fs-5'></i>}
                                    </span>
                                   </div>
                                   <div className="row mb-4">
                                   <div className="col-6 d-flex justify-content-center">
                                    <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="remember" defaultChecked />
                                    <label className="form-check-label" htmlFor="remember">Remember me</label>
                                    </div>
                                    </div>
                                   <div className="col-6 d-flex justify-content-center">
                                   <a href="#!">Forgot password?</a>
                                   </div>
                                   </div>
                                   <div className="text-center">
                                    <button type="button" className="btn btn-success btn-block mb-4" onClick={login}>Sign in</button>
                                    </div>
                                    <div className="text-center">
                                    <p>Not a member? <a href="/signup" >Register</a></p>   
                                    <p>or sign up with:</p>
                                    <hr className='text-primary'></hr>
                                    <div className='button-social'>
                                    <button type="button" className="btn btn-link btn-floating mx-1">
                                    <Link className='social-icon'  to="https://web.facebook.com/">   
                                    <img src={Fb} alt='' className='fb'/> </Link>
                                    </button>
                                    <button type="button" className="btn btn-link btn-floating mx-1">
                                    <Link className='social-icon'  to="https://x.com/i/flow/login">   
                                    <img src={Twit} alt='' className='twit'/> </Link>
                                    </button>
                                    <button type="button" className="btn btn-link btn-floating mx-1">
                                    <Link className='social-icon'  to="https://web.telegram.org">   
                                    <img src={Tel} alt='' className='teleg'/> </Link>
                                    </button>
                                    <button type="button" className="btn btn-link btn-floating mx-1">
                                    <Link className='social-icon'  to="https://accounts.google.com/">   
                                    <img src={Mail} alt='' className='mail'/> </Link>
                                    </button>
                                    </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
            
     <Footer/>
     </>
  )
}

export default SignIn;
