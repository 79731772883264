import React from 'react';
import axios from 'axios'
import {useState} from 'react'
// import {useNavigate} from 'react-router-dom'



const Edit = ({ id, fname, lname, email, number, password, gender, birthday, adress, brgy, street, role, setArr}) => {
   

    const [ufname, setFname] = useState(fname);
    const [ulname, setLname] = useState(lname);
    const [uemail, setEmail] = useState(email);
    const [unumber, setNumber] = useState(number);
    const [upassword, setPassword] = useState(password);
    const [ugender, setGender] = useState(gender);
    const [ubirthday, setBirtday] = useState(birthday);
    const [uadress, setAdress] = useState(adress);
    const [ubrgy, setBrgy] = useState(brgy);
    const [ustreet, setStreet] = useState(street);
    const [urole, setRole] = useState(role);
    

  const updateForm = (e) =>{
    //  e.currentTarget.id
      let getData = new FormData();
      
      getData.append('fname', ufname);
      getData.append('lname', ulname);
      getData.append('email', uemail);
      getData.append('number', unumber);
      getData.append('password', upassword);
      getData.append('gender', ugender);
      getData.append('birthday', ubirthday);
      getData.append('adress', uadress);
      getData.append('brgy', ubrgy);
      getData.append('street', ustreet);
      getData.append('role', urole);
      getData.append('id', e.currentTarget.id);
   
      
       axios({
           method : 'POST',
            url : 'https://itaypalengke.com/public/index.php/api/sample_api/edit/' + e.currentTarget.id,
            data : getData,
        }).then((response)=>{
            console.log(response.data.data);
            alert("success");
            axios.get('https://itaypalengke.com/public/index.php/api/sample_api').then((res)=>{
                setArr (res.data.data);
           })
  
    
   })
  }
 return (
        
        <div>
        <div className="modal fade" id={"editModal" + id} tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="editModalLabel">User Information</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
            <div className="modal-body">
             <form className="loginForm" >
                     <div className='row'>
                        <div className="col-6 mb-4">
                                  First Name : <br/>
                                <input type="text" id="fname" name='fname' value={ufname} onChange={(e)=> setFname(e.target.value)}className="form-control" placeholder='First Name' />  
                               </div>
                        <div className="form-outline col-6 mb-4">
                                  Last Name : <br/>
                                <input type="text" id="lname" name='lname' value={ulname} onChange={(e)=> setLname(e.target.value)} className="form-control" placeholder='Last Name' />
                               </div>
                        <div className="form-outline col-6 mb-4">
                                 Birthday : mm/dd/yr<br/>
                                <input type="text" id="birthday" name='birthday' value={ubirthday} onChange={(e)=> setBirtday(e.target.value)} className="form-control" placeholder='Birthday' />
                               </div>
                            <div className="form-outline col-6 mb-3">
                               Mobile number : <br/>
                                <input type="text" id="number" name='number' value={unumber} onChange={(e)=> setNumber(e.target.value)} className="form-control" placeholder='Mobile Number' />
                               </div>
                            <div className="form-outline col-6 mb-4">
                                Password : <br/>
                                <input type="password" id="password" name='password' value={upassword} onChange={(e)=> setPassword(e.target.value)} className="form-control" placeholder='Password'/>
                                </div>
                            <div className="form-outline col-6 mb-4">
                                 Gender : <br/>
                                <input type="text" id="gender" name='gender' value={ugender} onChange={(e)=> setGender(e.target.value)} className="form-control" placeholder='Gender' />
                                </div>
                            <div className="text-center mb-4">
                                Email adress : <br/>
                                <input type="text" id="email" name='email' value={uemail} onChange={(e)=> setEmail(e.target.value)} className="form-control" placeholder='Email' />
                                 </div>
                            <div className="form-outline mb-4">
                                Adress : city/province <br/>
                                <input type="text" id="adress" name='adress' value={uadress} onChange={(e)=> setAdress(e.target.value)} className="form-control" placeholder='Adress' />
                                </div>
                            <div className="form-outline col-6 mb-4">
                                Brgy : <br/>
                                <input type="text" id="brgy" name='brgy' value={ubrgy} onChange={(e)=> setBrgy(e.target.value)} className="form-control" placeholder='Brgy' />
                                </div>
                            <div className="form-outline col-6 mb-4">
                                Street : <br/>
                                <input type="text" id="street" name='street' value={ustreet} onChange={(e)=> setStreet(e.target.value)} className="form-control" placeholder='Street' />
                                </div>
                                <div className="form-outline col-6 mb-4">
                                Role : <br/>
                                <input type="text" id="role" name='role' value={urole} onChange={(e)=> setRole(e.target.value)} className="form-control" placeholder='admin/user' />
                                </div>
                    
                          
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" id={id} onClick={updateForm}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
        
    )
 }
export default Edit;
