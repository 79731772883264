import React from 'react';
import { useState, useEffect } from 'react'; 
import { Link, useNavigate } from 'react-router-dom';
import logo from '../image/itays.png';
import '../components/style/NavBar.css';

const NavBar = ({ cartItemCount }) => {
        const [role, setrole] = useState("user");  
        const [nav, setNav] = useState();
        const [user, setUser] = useState();
        const navigate = useNavigate();
        const [cartItemCounts, setCartItemCount] = useState(0);
  
        //Fetch user role and username from session storage
useEffect(() => {
        setrole(sessionStorage.getItem('role'));
        setUser(sessionStorage.getItem('username'));

         // Set navigation visibility based on role
        if (sessionStorage.getItem('role') == "user") {
          setNav("visible"); 
        } else if (sessionStorage.getItem('role') == "admin") {
          setNav("visible"); 
      
        } else if (sessionStorage.getItem('role') == null) {
          setNav("hidden"); 
        }
  }); 
            // navigate to the appropriate page based on role
const navi = useNavigate();
      const direction = () => {
          if (sessionStorage.getItem('role') == 'user'){
            navi('/shop');
          }else{
            navi('/dashboard');
          }
          }

 const logout = () => {
        sessionStorage.removeItem('login');  
        sessionStorage.removeItem('role'); 
        sessionStorage.removeItem('final');
        setCartItemCount(0); 
        navigate('/home');
      }

  return (
         <nav className="navbar navbar-expand-lg sticky-top ">
               <div className="container-fluid">
                    <Link className="navbar-brand col-s" to="/home">
                       <img src={logo} alt='' className='logo' /> 
                          </Link>
                              <button className="navbar-toggler p-2 border border-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                              <span className="navbar-toggler-icon border border-light"></span>
                              </button>
                              <div className="collapse navbar-collapse " id="navbarSupportedContent">
                              <ul className="navbar-nav ms-auto">
                              <li   className = "nav-item">
                              <Link className = "nav-a p-4 active" aria-current = "page" to = "/home">Home</Link>
                              </li>
                              <li   className = "nav-item">
                              <Link className = "nav-a p-4" to = "/shop">Shop</Link>
                              </li>
                              <li   className = "nav-item">
                              <Link className = "nav-a p-4" to = "/about">About</Link>
                              </li>
                              <li   className = "nav-item">
                              <Link className = "nav-a p-4" to = "/contact">Contact</Link>
                              </li>
                              <li   className = "nav-item p-2">
                              <Link className = "btn text-light fs-5" to = "/signin"> <i className = 'bi bi-person-vcard me-2 fs-6'></i>Signin </Link>
                              </li>
                              <li   className = "nav-item p-2">
                              <Link className = "btn text-light fs-5" to = "/signup"> <i className = 'bi bi-person-vcard me-2 fs-6'></i>Signup</Link>
                              </li>
                              <li   className = "nav-item p-2">
                              <button className = "btn btn-danger text-light me-4 fs-5" onClick = {logout}><i className = 'bi bi-power text-light me-2 fs-5'></i>Logout</button>
                              </li>
                              <li>
                                <Link className = "cart-icon p-2" to = "/cart"><i className = 'bi bi-cart4 text-white fs-2'></i>
                                      {cartItemCount > 0 && <span className="badge bg-danger">{cartItemCount}</span>} {/* Show count */}
                                </Link>
                              </li>
                              <li     className = "nav-item-user">
                              <button className = "btn text-light"  onClick = {direction} style = {{ visibility: nav }}><i className = 'bi bi-person-fill me-4 fs-5'>{user} </i></button>
                                </li>
                          </ul>
                   </div>
              </div>
        </nav>
  )
}

export default NavBar;