import React from 'react';
import axios from 'axios'
import {useState} from 'react'

const EditProd = ({arr, setArr, id, name, category, price, description, image}) => {
   
                const [editname, setEName] = useState(name);
                const [editcategory, setECategory] = useState(category);
                const [editprice, setEPrice] = useState(price);
                const [editdescription, setEDescription] = useState(description);
                const [editImage, setEimage] = useState(image);
                const [editImageF, setEimageF] = useState(image);
                
                const url = 'https://itaypalengke.com/public/index.php/api/product_api'; 

const preview = (e) =>{
                    setEimage(e.target.files[0]);
                    setEimageF(e.target.files[0].name);
                    console.log(editImageF);
                }
   const editSubmit = (e) =>{   
                    console.log(editname, editprice)
                    e.preventDefault();     
                    let getData = new FormData();
                    getData.append('eid', e.currentTarget.title);
                    getData.append('editname', editname);
                    getData.append('editcategory', editcategory);
                    getData.append('editprice', editprice);
                    getData.append('editdescription', editdescription);
                    getData.append('editimage',editImage);
                    getData.append('editimageF',editImageF);
               
                    axios({
                        method: 'POST',
                        data: getData,
                        url: 'https://itaypalengke.com/public/index.php/api/product_api/edit/' + e.currentTarget.title,
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then((res) => {
                        alert("success");
                        return axios.get(url);
                    })
                    .then((res) => {
                        setArr(res.data.data);
                    })
                    .catch((error) => {
                        console.error("Error editing product:", error.response ? error.response.data : error.message);
                    });
  }
 return (
        <div>
           <div className="modal fade" id={"editModal" + id} tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
             <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="editModalLabel">Edit Product</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                               </div>
                                   <div className="modal-body">
                                       <form className='form-group'>
                                             <div className='row'>
                                                 <div className="col-6 mb-4">
                                                Name : <b/>
                                                  <input type="text" id="name" name='name' value={editname} onChange={(e) => setEName(e.target.value)} className="form-control" placeholder='Product Name' required />
                                                  </div>
                                                  <div className="col-6 mb-4">
                                                Category : <b/>
                                                    <input type="text" id="category" name='category' value={editcategory} onChange={(e) => setECategory(e.target.value)} className="form-control" placeholder='Category' required />
                                                    </div>
                                                     <div className="col-6 mb-4">
                                                Price : <b/>
                                                    <input type="number" id="price" name='price' value={editprice} onChange={(e) => setEPrice(e.target.value)} className="form-control" placeholder='Price' required />
                                                    </div>
                                                    <div className="col-6 mb-4">
                                                 Description: <b/>
                                                    <textarea id="description" name='description' value={editdescription} onChange={(e) => setEDescription(e.target.value)} className="form-control" placeholder='Description' required></textarea>
                                                    </div>
                                                    <div className="col-6 mb-4">
                                                Image : <b/>
                                                    <input type="file" id="eimage" name='eimage'  className="form-control"   onChange={preview}/>
                                                    </div>
                                                    </div>
                                                    </form>
                                                    </div>
                                             <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={editSubmit} title={id}>Save changes</button>                                     
                             </div>
                         </div>
                    </div>
               </div>                                                                 
        </div>
        
    )
 }
export default EditProd;
