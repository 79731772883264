import React from 'react'
import Admin from '../formgroup/mar.png'
import Footer from '../../navfooter/Footer';
import '../style/Profile.css'



const Profile = () => {
  return (
    <>
          <div className="container">
                <div className="row justify-content-center p-5">
                  <div className="col-sm-12 col-md-6">
                    <div className="container p-5 border border-success">
                      <img src={Admin} className="card-img-top" alt="Admin" /><br/>
                      <div className="card-body text-center">
                        <h4 className="card-title">" Admin Creator "</h4> <br/>
                        <div className="row border border-dark-subtle">
                          <div className="col-6 col-md-6text-center">
                            <label className="form-label" htmlFor="fName">Name</label>
                            <input type="text" className="form-control" id="fName" placeholder="Marlon Vencito" />
                            <label className="form-label" htmlFor="email">Email Address</label>
                            <input type="email" className="form-control" id="email" placeholder="vencitomarl@gmail.com" />
                          </div>
                          <div className="col-6 text-center">
                            <label className="form-label" htmlFor="company">Company</label>
                            <input type="text" className="form-control" id="company" placeholder="i-Tay Palengke" />
                            <label className="form-label" htmlFor="phone">Phone</label>
                            <input type="text" className="form-control" id="phone" placeholder="09615806261" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              
      <Footer />
    </>
  );
}

export default Profile