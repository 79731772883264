import React from 'react'
import './../../components/style/ShopPagination.css'

const PaginationShop = ({ postsPerPage, totalPosts, paginate }) => {
    const pageNumbers = [];
    
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }
    return (
        <>
            <ul className="pagination"><i className='bi bi-arrow-right text-success me-2 fs-4'></i>
                {
                    pageNumbers.map(number => {
                        return (
                            <li key={number} className="page-item">
                                <a onClick={() => paginate(number)} href="#" className="page-link text-light bg-dark">
                                    {number}
                                </a>
                            </li>
                        );
                    })
                }
            </ul>
        </>
    );
}

export default PaginationShop;