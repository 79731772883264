import React from 'react'
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom'
import Footer from '../../navfooter/Footer';
import '../style/DashBoard.css';
import Man from '../../image/contact.png'





const Dashboard = () => {
    const navigate = useNavigate();

       const logout = ()=>{
       sessionStorage.removeItem('login');
       navigate('/home');
    }

    

  return (
    <>  
     <h1 className='text-success text-center'>DashBoard</h1>
       <hr/>
        <div className='dashboard'>
                <div className='row'>
                    <div className='container-fluid  min-vh-50'>
                        <div className='col-4 col-md-2 bg-white vh-50'>
                            <div className='bg-white p-2 d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <i className='bi bi-person-vcard-fill me-2 fs-4 text-success'></i>
                                    <span className='brand-name text-secondary col-md-2 fs-2'>Marlon</span> 
                                </div>
                            </div>
                            <hr className='text-dark'/>
                            <div className='list-group list-group-flush'>
                                <a className='list-group-item py-2'>
                                    <i className='bi bi-person fs-5 me-2'></i>
                                    <Link className='btn fs-5 text-success' to='/admin/profile'>Admin Profile</Link>
                                </a>
                                <a className='list-group-item py-2'>
                                    <i className='bi bi-table fs-5 me-3'></i>
                                    <Link className='btn fs-5 text-success' to='/admin/products'>Products Creation</Link>
                                </a>
                                <a className='list-group-item py-2'>
                                    <i className='bi bi-clipboard-data fs-5 me-3'></i>
                                    <Link className='btn fs-5 text-success' to='/categorylist'>Category Creation</Link>
                                </a>
                                <a className='list-group-item py-2'>
                                    <i className='bi bi-bag-fill fs-5 me-3'></i>
                                    <Link className='btn fs-5 text-success' to='/display'>Users Order</Link>
                                </a>
                                <a className='list-group-item py-2'>
                                    <i className='bi bi-people fs-5 me-3'></i>
                                    <Link className='btn fs-5 text-success' to='/feedback'>Users Feedback</Link>
                                </a>
                                <a className='list-group-item py-2'>
                                    <i className='bi bi-people fs-5 me-3'></i>
                                    <Link className='btn fs-5 text-success' to='/userinfo'>Users Info</Link>
                                </a>
                                <a className='list-group-item py-2'>
                                    <i className='bi bi-power fs-5 me-3 text-danger'></i>
                                    <span className='fs-5 text-danger'onClick={logout}>Logout</span>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                </div>
                </div>
       
  
        
                    <Footer/>
     </>
  )
}

export default Dashboard