const MoneyFormat = (amount) => {
    if (isNaN(amount)) {
        return '';
    }

    let formattedAmount = parseFloat(amount).toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return formattedAmount;
};

export default MoneyFormat;