import React from 'react';

const View = ({ id, fname, lname, email, mobile, password, gender, birthday, adress, brgy, street, role }) => {
    return (
        <div className="modal fade" id={"viewModal" + id} tabIndex="-1" aria-labelledby="viewModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="viewModalLabel">User Details</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        
                        <ul>
                            <li>First Name: {fname}</li>
                            <li>Last Name: {lname}</li>
                            <li>Email: {email}</li>
                            <li>Mobile: {mobile}</li>
                            {/* <li>Password: {password}</li> */}
                            <li>Gender: {gender}</li>
                            <li>Birthday: {birthday}</li>
                            <li>Address: {adress}</li>
                            <li>Brgy: {brgy}</li>
                            <li>Street: {street}</li>
                            <li>Role: {role}</li>
                        </ul>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default View;































// import React from 'react'

// const View = (id) => {
//   return (
//     <div>

//                 {/* <!-- Modal --> */}
//                 <div class="modal fade" id={"viewModal"+id} tabindex="-1" aria-labelledby="viewModalLabel" aria-hidden="true">
//                 <div class="modal-dialog">
//                     <div class="modal-content">
//                     <div class="modal-header">
//                         <h1 class="modal-title fs-5" id="viewModalLabel">Modal title</h1>
//                         <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                     </div>
//                     <div class="modal-body">
//                         ...
//                     </div>
//                     <div class="modal-footer">
//                         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//                         <button type="button" class="btn btn-primary">Save changes</button>
//                     </div>
//                     </div>
//                 </div>
//                 </div>
//     </div>
//   )
// }

// export default View