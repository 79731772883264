import React, { useEffect, useState } from 'react';
import axios from 'axios';
import UserOrder from './UserOrder';
import { useNavigate } from 'react-router-dom';
import Footer from '../navfooter/Footer';


const DisplayUserOrder = () => {
    const url = "https://itaypalengke.com/public/index.php/api/cart_api";
    const [arr, setArr] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(url);
                console.log('response', response);
                setArr(response.data.data); 
                console.log("response data here",response.data); 
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

 return (
        <>
            <UserOrder arr={arr} setArr={setArr} />
            <Footer />
        </>
    );
};

export default DisplayUserOrder;