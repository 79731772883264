import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useState, useEffect} from 'react'
import axios from 'axios'

const Create = () => {
       const [prodName, setProdName] = useState();
       const [prodCat, setProdCat] = useState();
       const [prodPrice, setProdPrice] = useState();
       const [prodDesc, setProdDesc] = useState();
       const [prodImg, setProdImg] = useState();
       const [prodImgPrev, setProdImgPrev] = useState();
       const [ProdImgForDB, setProdImgProdImgForDB] = useState();
       const [products, setProducts] = useState([]);
       let nav = useNavigate(); 
       function preview(e){
            setProdImg((e.target.files[0]));
            setProdImgProdImgForDB((e.target.files[0]).name)
            setProdImgPrev(URL.createObjectURL(e.target.files[0]));
      
       }

       const updateProd = () =>{
          let getData = new FormData();
          
          getData.append('name', prodName); 
          getData.append('category', prodCat);
          getData.append('price', prodPrice);
          getData.append('description', prodDesc);
          getData.append('image', prodImg.name);
          getData.append('image_file', prodImg);

          axios({
               method : 'POST',
                url : 'https://itaypalengke.com/public/index.php/api/product_api/send',
                data : getData,
                config: {header:"Content-Type: multipart/form-data"}
            }).then((response)=>{
                console.log(response.data.data);
                alert("success");
                axios.get('https://itaypalengke.com/public/index.php/api/product_api').then((res)=>{
                    setProducts (res.data.data);           
               })
       })
      }
 return (  
         <div className='container my-4'>
            <div className='row'>
                <div className='col-md-8 mx-auto rounded border border-black p-4'>
                   <h2 className='text-center mb-4'>Create Product</h2>
                      <form>
                             <div className='row mb-3 m-4'>
                    <           label className='col-sm-4 col-form-label'>Name</label>
                                   <div className='col-sm-8'>
                                     <input className='form-control' name='name' onChange={(e) => setProdName(e.target.value)}/>
                                      <span className='text-danger'></span>
                                         </div>
                                              </div>
                                                    <div    className = 'row mb-3 m-4'>
                                                    <label  className = 'col-sm-4 col-form-label'>Category</label>
                                                    <div    className = 'col-sm-8'>
                                                    <select className = 'form select' name = 'category' onChange = {(e) => setProdCat(e.target.value)} required>
                                                    <option value     = ''>Select Category</option>
                                                    <option value     = 'Other'>Other</option>
                                                    <option value     = 'Vegetables'>Vegetables</option>
                                                    <option value     = 'Fruits'>Fruits</option>
                                                    <option value     = 'Meat'>Meat</option>
                                                    <option value     = 'Seafood'>Seafood</option>
                                                    <option value     = 'Dried Fish'>Dried Fish</option>
                                                    </select>
                                                    <span className='text-danger'></span>
                                                    </div>
                                                    </div> 
                                                    <div className='row mb-3 m-4'>
                                                    <label className='col-sm-4 col-form-label'>Price</label>
                                                    <div className='col-sm-8'>
                                                    <input className='form-control' name='price' type='number' step='0.01' min='1' onChange={(e) => setProdPrice(e.target.value)} required/>
                                                    <span className='text-danger'></span>
                                                    </div>
                                                    </div>
                                                    <div className='row mb-3 m-4'>
                                                    <label className='col-sm-4 col-form-label'>Description</label>
                                                    <div className='col-sm-8'>
                                                    <textarea className='form-control' name='description' rows='4' onChange={(e) => setProdDesc(e.target.value)} required></textarea>
                                                    <span className='text-danger'></span>
                                                    </div>
                                                   </div>
                                                   <div className='row mb-3 m-4'>
                                                   <label className='col-sm-4 col-form-label'>Image</label>
                                                  <div className='col-sm-8'>
                                                <input className='form-control' type='file' name='image' onChange={preview} required/>
                                              <span className='text-danger'></span>
                                            </div>
                                         </div>
                                        <img src={prodImgPrev} style={{width: "100px"}} alt={prodImg}/>
                                      <div className='row'>
                                     <div className='offset-sm-4 col-sm-4 d-grid'>
                                  <button type='button' className='btn btn-primary' onClick={updateProd}>Submit</button>
                               </div>
                             <div className='col-sm-4 d-grid'>
                          <Link className='btn btn-secondary' to='/admin/products' role='button'>Cancel</Link>
                         </div>
                      </div>
                   </form>
                  </div>
               </div>
             </div>
           )
        }
export default Create;