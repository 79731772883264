import React from 'react'
import View from './View'
import Edit from './Edit'
import Delete from './Delete'

const Card = ({ arr, setArr }) => {

  return (
    <>
     <div className='container p-5 col-sm-12 col-md-6 col-lg-12 border border-success'>
      <h1 className='text-center'>Users Submit Form</h1>

    {arr.map((item)=>{
        return(
      
          <div className="d-inline-block p-4 mb-8 border border-dark" style={{ width: "18rem", margin: "8px" }} key={item.id}>
              <div className="col-12 mb-4 ">
                <ul>
                  <li className="card-title">First Name:{item.fname}</li>
                  <li className="card-title">Last Name : {item.lname}</li>
                  <li className="card-title">Email:{item.email}</li>
                  <li className="card-title">Mobile :<br/>{item.number}</li>
            
                  {/* <li className="card-title">Password:<br/>{item.password}</li> */}
                  <li className="card-title">Gender : <br/>{item.gender}</li>
                  <li className="card-title">Birthday:<br/>{item.birthday}</li>
                  <li className="card-title">Adress :<br/>{item.adress}</li>
                  <li className="card-title">Brgy : <br/>{item.brgy}</li>
                  <li className="card-title">Street: <br/>{item.street}</li>
                  <li className="card-title">Role: <br/>{item.role}</li>
              
            </ul>  
      
        <div className='card-footer mb-4 text-center'>
        
                                 <button 
                                        className="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target={"#viewModal"+item.id}
                                      > View</button>
                                <button 
                                        className="btn btn-success" 
                                        data-bs-toggle="modal"
                                        data-bs-target={"#editModal"+item.id}
                                 
                                    >  Edit</button>
                                <button 
                                        className="btn btn-danger"
                                        data-bs-toggle="modal"
                                        data-bs-target={"#delModal"+item.id}   
                                    >Delete</button>

        </div>
        
        <View 
            id={item.id} 
            fname={item.fname} 
            lname={item.lname}  
            email={item.email} 
            mobile={item.number} 
            password={item.password}
            gender={item.gender}
            birthday={item.birthday}
            adress={item.adress}
            brgy={item.brgy}
            street={item.street}
            role={item.role}
             />
          <Edit
            id={item.id} 
            fname={item.fname} 
            lname={item.lname}  
            email={item.email} 
            mobile={item.number} 
            password={item.password}
            gender={item.gender}
            birthday={item.birthday}
            adress={item.adress}
            brgy={item.brgy}
            street={item.street}
            role={item.role}
            arr={arr} setArr={setArr}
            
             />
            <Delete
            id={item.id} 
            fname={item.fname} 
            lname={item.lname}  
            email={item.email} 
            mobile={item.number} 
            password={item.password}
            gender={item.gender}
            birthday={item.birthday}
            adress={item.adress}
            brgy={item.brgy}
            street={item.street}
            role={item.role}
            arr={arr} setArr={setArr}
            
             />


        </div>
        
           </div>
        )
       
    })
    
    }
  </div>
</>

  )
}

export default Card


