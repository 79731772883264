import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../navfooter/Footer';

const Contact = () => {
            const navigate = useNavigate();
            const [fname, setFname] = useState('');
            const [company, setCompany] = useState('');
            const [email, setEmail] = useState('');
            const [phone, setPhone] = useState('');
            const [subject, setSubject] = useState('');
            const [message, setMessage] = useState('');

            const shop = () => {
                navigate('/shop');
    }
const formSubmit = (e) => {
            e.preventDefault();
            let getData = new FormData();
            getData.append('fname', fname);
            getData.append('company', company);
            getData.append('email', email);
            getData.append('phone', phone);
            getData.append('subject', subject);
            getData.append('message', message);

 axios.post('https://itaypalengke.com/public/index.php/api/feedback_api/send', getData)
            .then((response) => {
                alert("success");
                setFname("");
                setCompany("");
                setEmail("");
                setPhone("");
                setSubject("");
                setMessage("");
            });
    }

    return (
        <>
            <div className="hero d-flex flex-column align-items-center justify-content-center">
                <h1 className="text-success text-center p-2">Contact Us</h1>
                <div className='container border border-success mt-4'>
                    <div className='row row-cols-1 row-cols-md-2 justify-content-between align-items-center'>
                        <div className='col p-4'>
                            <h5 className='p-2 text-secondary'>
                                The most quality and fresh products with good prices, Shop now for fast delivery and excellent customer service.
                            </h5>
                            <button className='btn custom-button bg-success text-white border-dark fs-5' onClick={shop}>Shop Now</button>
                        </div>
                        <div className='col d-flex justify-content-center'>
                            <img className="img-fluid" src={require('../image/b.png')} alt='...' width="400px" />
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="container container-form p-4 border border-success d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                   <form className='loginForms' onSubmit={formSubmit}>
                        <div className="row">
                                <div className="col-md-6 col-sm-12 mb-4">
                                   <h2 className="title mb-5 text-success text-center">GET IN TOUCH</h2>
                                      <hr className='text-primary' />
                                       <h5>You can direct message and visit our place for some inquiries...</h5>
                                        <h5 className='text-primary'>Head Office :</h5>
                                         <p><i className='bi-send fs-5 me-3 text-success'></i> Bagacay San Miguel Leyte</p>
                                         <h5 className='text-primary'>Email us :</h5>
                                         <p><i className='bi-envelope fs-5 me-3 text-success'></i> vencitomarl@gmail.com</p>
                                       <h5 className='text-primary'>Call us :</h5>
                                       <p><i className='bi-telephone fs-5 me-3 text-success'></i> 09615806261</p> </div>
                                   <div className="col-md-6 col-sm-6 mb-8 text-start">
                                     <h2 className="title mb-5 text-success text-center">LEAVE US MESSAGE</h2>
                                        <hr className='text-primary' />
                                           <h5>Give us feedback by our Palengke, and we serve you anytime.</h5>
                                              <div className="row border border-dark-subtle">
                                                <div className="col-6">
                                                 <label className="form-label" htmlFor="fName">Full Name :</label>
                                                 <input type="text" className="form-control" id="fName" placeholder="Full name" value={fname} onChange={(e) => setFname(e.target.value)} />
                                               <label className="form-label" htmlFor="email">Email Address :</label>
                                           <input type="email" className="form-control" id="email" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} /></div>
                                       <div className="col-6">
                                         <label className="form-label" htmlFor="company">Company :</label>
                                              <input type="text" className="form-control" id="company" placeholder="Company" value={company} onChange={(e) => setCompany(e.target.value)} />
                                                  <label className="form-label" htmlFor="phone">Phone :</label>
                                                    <input type="text" className="form-control" id="phone" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} /> </div>       
                                                   <label className="form-label" htmlFor="subject">Subject :</label>
                                                 <input type="text" className="form-control" id="subject" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                                <label className="form-label" htmlFor="message">Message :</label>
                                              <textarea className="form-control" id="message" rows="6" style={{ resize: "none" }} placeholder="Enter your message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                           <div className="col-12 text-center">
                                    <button type="submit" className="btn btn-success mt-1">Send Message</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer />
        </>
    );
}

export default Contact;