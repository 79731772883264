import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import Card from './Card';
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom'
import Footer from '../../navfooter/Footer';
import '../style/DashBoard.css';
import Man from '../../image/contact.png'





const UserInfo = () => {
    const url = "https://itaypalengke.com/public/index.php/api/sample_api";
    const [arr, setArr] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
         axios.get(url).then((res)=>{
              setArr (res.data.data)
        
         });
      
      
  },[]);
     

  return (
       <>  
                    <Card arr={arr} setArr={setArr}/>
                    <Footer/>
       </>
  )
}

export default UserInfo