import React from 'react'
import axios from 'axios'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Footer from '../navfooter/Footer'



const SignUp = () => {    // State variables to store user input
     const [fname, setFname] = useState();
     const [lname, setLname] = useState();
     const [email, setEmail] = useState();
     const [number, setNumber] = useState();
     const [password, setPassword] = useState();
     const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility
     const [gender, setGender] = useState();
     const [birthday, setBirtday] = useState();
     const [adress, setAdress] = useState();
     const [brgy, setBrgy] = useState();
     const [street, setStreet] = useState();
     const [role, setRole] = useState();
     
     const navigate = useNavigate();
     const url = 'https://itaypalengke.com/public/index.php/api/sample_api/send';
     const bcrypt = require('bcryptjs');
     const saltRounds = bcrypt.genSaltSync(10); // Hash the password before sending it to the API for security
     

   const formSubmit = (e) =>{
        console.log(
            fname, 
            lname, 
            email, 
            number,
            password,
            gender,
            birthday,
            adress,
            brgy,
            street,
            role
        );
       e.preventDefault();  // Prevent default form submission behavior
       let getData = new FormData();// FormData object to send data to the API
       getData.append('fname', fname);  
       getData.append('lname', lname);
       getData.append('email', email);
       getData.append('number', number);
       getData.append('password', bcrypt.hashSync(password, saltRounds));
       getData.append('gender', gender);
       getData.append('birthday', birthday);
       getData.append('adress', adress);
       getData.append('brgy', brgy);
       getData.append('street', street);
       
        axios(
           { method : 'POST',
            url : url,
           data : getData
         }).then((response)=>{
              console.log(response.data.data);
              alert("success");
              setFname("");
              setLname("");
              setEmail("");
              setNumber("");
              setPassword("");
              setGender("");
              setBirtday("");
              setAdress("");
              setBrgy("");
              setStreet("");
              navigate('/shop')
         })
   }
  return (
    <>
     <div className="container mt-5 p-3 col-sm-12 col-md-12 loginContainer">
        <div className="row justify-content-center">
                    <div className="col-12 col-md-6 ">
                         <div className='container p-5 border border-success'>
                        <h1 className = "text-center text-success mb-4 p-4">Registration Form</h1>
                        <hr className = 'text-primary mb-4'></hr>
                               <form className="loginForm" onSubmit={formSubmit}>
                               <div className = 'row'>
                               <div className = "col-md-6 col-sm-12 mb-4">
                     First Name: <br/>
                               <input type = "text" id = "fname" name = 'fname' value = {fname} onChange = {(e)=> setFname(e.target.value)}className = "form-control" placeholder = 'First Name' />
                               </div>
                               <div className = "col-md-6 col-sm-12 mb-4">
                     Last Name: <br/>
                                <input type = "text" id = "lname" name = 'lname' value = {lname} onChange = {(e)=> setLname(e.target.value)} className = "form-control" placeholder = 'Last Name' />
                                </div>
                                <div className = "form-outline col-6 mb-4">
                     Birthday: mm/dd/yr<br/>
                                <input type = "text" id = "birthday" name = 'birthday' value = {birthday} onChange = {(e)=> setBirtday(e.target.value)} className = "form-control" placeholder = 'Birthday' />
                                </div>
                                <div className = "form-outline col-6 mb-3">
                     Mobile number: <br/>
                                <input type = "text" id = "number" name = 'number' value = {number} onChange = {(e)=> setNumber(e.target.value)} className = "form-control" placeholder = 'Mobile Number' />
                                </div>
                                <div className='text-center'>
                     Password:  <br />
                                <div   className = "form-outline position-relative">
                                <input type  = {passwordVisible ? 'text' : 'password'} id = "password" value = {password} onChange = {(e) => setPassword(e.target.value)} className = "form-control" placeholder = 'Password' />
                                <span  className = "position-absolute" style = {{ right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }} onClick = {() => setPasswordVisible(!passwordVisible)}>
                                {passwordVisible ? <i className='bi bi-eye-slash me-2 fs-5'></i> : <i className='bi bi-eye me-2 fs-5'></i>}
                                 </span>
                                 </div>
                                 </div>
                                <div className = "form-outline col-12 mb-4">
                     Gender:    <br/>
                                <input type = "text" id = "gender" name = 'gender' value = {gender} onChange = {(e)=> setGender(e.target.value)} className = "form-control" placeholder = 'Gender' />
                                </div>
                                <div className = "text-center mb-4">
                     Email adress: <br/>
                                <input type = "text" id = "email" name = 'email' value = {email} onChange = {(e)=> setEmail(e.target.value)} className = "form-control" placeholder = 'Email' />
                                 </div>
                                <div className = "form-outline mb-4">
                     Adress: city/province <br/>
                                <input type = "text" id = "adress" name = 'adress' value = {adress} onChange = {(e)=> setAdress(e.target.value)} className = "form-control" placeholder = 'Adress' />
                                </div>
                                <div className = "form-outline col-6 mb-4">
                     Brgy:      <br/>
                                <input type = "text" id = "brgy" name = 'brgy' value = {brgy} onChange = {(e)=> setBrgy(e.target.value)} className = "form-control" placeholder = 'Brgy' />
                                </div>
                                <div className = "form-outline col-6 mb-4">
                     Street:    <br/>
                                <input type = "text" id = "street" name = 'street' value = {street} onChange = {(e)=> setStreet(e.target.value)} className = "form-control" placeholder = 'Street' />
                                </div>
                                <div  className = "text-center">
                                <button type      = "submit" className = "btn btn-dark bg-success btn-block mb-4">Register</button>
                                </div>
                               </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     <Footer/>
     </>
  )
}

export default SignUp