
import Footer from '../navfooter/Footer';
import Header from '../components/Header/Header'
import React, { useEffect } from 'react'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import '../components/style/Home.css';

const Home = () => {
                const navigate = useNavigate();
                const [prodCatname, setProdCatname] = useState();
                const [prodDesc, setProdDesc] = useState();
                const [prodImg, setProdImg] = useState();
                const [prodImgPrev, setProdImgPrev] = useState();
                const [ProdImgForDB, setProdImgProdImgForDB] = useState();
                const [arr, setArr] = useState([]);
                
              const shop = () =>{
                  navigate ('/shop')
              }
              useEffect(()=>{
                     axios.get('https://itaypalengke.com/public/index.php/api/category_api').then((response)=>{
                    setArr(response.data.data)
                })
            },[])
              function preview(e){
                    setProdImg((e.target.files[0]));
                    setProdImgProdImgForDB((e.target.files[0]).name)
                    setProdImgPrev(URL.createObjectURL(e.target.files[0]));  
              }
                function handleSubmit(e) {
                      e.preventDefault();
              
                let getData = new FormData();
                    getData.append('catname', prodCatname);
                    getData.append('description', prodDesc);
                    getData.append('img', prodImg.name);
                    getData.append('image_file', prodImg);
                
                    axios({
                      method : 'POST',
                      url : 'https://itaypalengke.com/public/index.php/api/category_api/send',
                      data : getData,
                      config: {header:"Content-Type: multipart/form-data"}
                }).then((response)=>{  
                    alert("success");             
          })
    }
return (
    <>      
       <Header />
            <br/>
                <br/>
                   <br/>
                   <br/>
                     <br/>
                     <br/>
                     <hr/>
                         <h3 className='text-center text-success'>Our Products</h3>
                          <h5 className='text-secondary'>Make your orders simple in our market fresh...</h5>
                         <button to="/shop" className="btn btn-success p-2 fs-5 mt-2 ms-4" onClick={shop} >Order Now</button>
                    <br/>
            {arr.map((item)=>{
 return(      
          <div className='d-inline-block '>
                  <div class="card" style={{width: "19rem"}}>
                       <img src={`https://itaypalengke.com/public/storage/images/${item.img}`}  className="card-img-top" alt={item.img}/>
                           <div className="card-body">
                          <h5 className="card-title" id= {item.id + "catname"}>{item.catname}</h5> 
                        </div>
                      </div>
                 </div>     
               )
           })}
    <hr/>
        <div className='container-fluids'>
          <div className="row mt-5">
                    <div className="col-lg-6 p-3">
                        <i className='bi bi-display-fill fs-2'></i>
                            <h4 className='text-success'>Why Choose Us</h4>
                               <h5 className='text-secondary'>Choose us our fresh market and very afford product can choose anytime our service . Our commitment to serve you better and find your easy and comfortable ways.</h5>
                                 <div className="row mt-5 feature">
                                   <div className="col-6">
                                      <i className='bi bi-truck fs-2 py-2'></i>
                                         <h4 className='text-success'>Fast & Free Shipping</h4>
                                            <h5 className='text-secondary'>Get your order delivered quickly with our expedited shipping service.</h5> </div>        
                                              <div className="col-6">
                                                <i className='bi bi-cart4 fs-2 me-2'></i>
                                               <h4 className='text-success'>Easy to Shop</h4>
                                             <h5 className='text-secondary'>We're here to help you anytime, day or night, with round-the-clock customer service.</h5>
                                           </div><div className="col-6">
                            <            i className='bi bi-clock fs-2 me-2'></i>
                                       <h4 className='text-success'>24/7 Support</h4>
                                     <h5 className='text-secondary'>Enjoy a seamless and user-friendly shopping experience on our market.</h5>
                                    </div><div className="col-6">
                                  <i className='bi bi-bag-dash fs-2 me-2'></i>
                                <h4 className='text-success'>Hassle Free Return</h4>
                             <h5 className='text-secondary'>Return items effortlessly with our straightforward and convenient return policy.</h5>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
           <Footer/>
    </>
  )
}

export default Home;













