import React from 'react';
import axios from 'axios'
import {useState} from 'react'
// import {useNavigate} from 'react-router-dom'



const Fedit = ({ id, fname, company, email, phone, subject, message, setArr}) => {
   

    const [efname, setFname] = useState(fname);
    const [ecompany, setCompany] = useState(company);
    const [eemail, setEmail] = useState(email);
    const [ephone, setPhone] = useState(phone);
    const [esubject, setSubject] = useState(subject);
    const [emessage, setMessage] = useState(message);

    

  const updateFeedBack = (e) =>{
    //  e.currentTarget.id
      let getData = new FormData();
      
      getData.append('fname', efname);
      getData.append('company', ecompany);
      getData.append('email', eemail);
      getData.append('phone', ephone);
      getData.append('subject', esubject);
      getData.append('message', emessage);
      getData.append('id', e.currentTarget.id);
   
      
       axios({
           method : 'POST',
            url : 'https://itaypalengke.com/public/index.php/api/feedback_api/edit/' + e.currentTarget.id,
            data : getData,
        }).then((response)=>{
            console.log(response.data.data);
            alert("success");
            axios.get('https://itaypalengke.com/public/index.php/api/feedback_api').then((res)=>{
                setArr (res.data.data);
           })
  
    
   })
  }
 return (
        
        <div>
        <div className="modal fade" id={"editModal" + id} tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="editModalLabel">User Information</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
            <div className="modal-body">
             <form className="loginForm" >
                     <div className='row'>
                        <div className="col-6 mb-4">
                                  Full Name : <br/>
                                <input type="text" id="fname" name='fname' defaultValue={fname} onChange={(e)=> setFname(e.target.value)}className="form-control" placeholder='Full Name' />  
                               </div>
                        <div className="form-outline col-6 mb-4">
                                 Company : <br/>
                                <input type="text" id="company" name='company' defaultValue={company} onChange={(e)=> setCompany(e.target.value)} className="form-control" placeholder='Company' />
                               </div>
                        <div className="form-outline col-6 mb-4">
                                Email: <br/>
                                <input type="text" id="email" name='birthday' defaultValue={email} onChange={(e)=> setEmail(e.target.value)} className="form-control" placeholder='Email' />
                               </div>
                            <div className="form-outline col-6 mb-3">
                              Phone number : <br/>
                                <input type="text" id="number" name='number' defaultValue={phone} onChange={(e)=> setPhone(e.target.value)} className="form-control" placeholder='number' />
                               </div>
                            <div className="form-outline col-6 mb-4">
                                Subject : <br/>
                                <input type="text" id="subject" name='subject' defaultValue={subject} onChange={(e)=> setSubject(e.target.value)} className="form-control" placeholder='subject'/>
                                </div>

                                <div className="form-outline col-6 mb-4">
                               <label className="form-label" htmlFor="message" >Message :</label>
                                    <textarea className="form-control" id="message" defaultValue={message} onChange={(e)=> setMessage(e.target.value)} rows="6" style={{resize: "none"}} placeholder="Enter your message"></textarea>
                                </div>
                          
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" id={id} onClick={updateFeedBack}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
        
    )
 }
export default Fedit;
