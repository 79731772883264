import React from 'react';
import axios from 'axios'
// import {useState} from 'react'

const Delete = ({ id, fname, email, setArr}) => {
    
   
     
     const delBtn = (e) =>{
        let getData = new FormData();
        getData.append('delid', e.currentTarget.id);
 
        
         axios( { 
              method : 'POST',
              url : 'http://127.0.0.1:8000/api/sample_api/delete/' + e.currentTarget.id,
              data : getData, 
          }).then((response)=>{
            console.log(response.data.data);
              alert("deleted");
              axios.get('https://itaypalengke.com/public/index.php/api/sample_api').then((res)=>{
                  setArr (res.data.data)
             });
    
      
     });
     }

  return (

    <div>
    <div className="modal fade" id={"delModal" + id} tabIndex="-1" aria-labelledby="delModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="delModalLabel">Are you sure?</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
        <div className="modal-body">
                     {fname}
                     {email}
                
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-danger" data-bs-dismiss="modal" id={id} onClick={delBtn}>Confirm Delete</button>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Delete